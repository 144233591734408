import React, { useState } from 'react';
import Content from '../Content';
import Header from '../Header';
import LandingPage from '../LandingPage';

function App() {
  const [appEntered, setAppEntered] = useState(false)

  return (
    <>
      {
        !appEntered ? 
          <LandingPage 
            exitAction={() => setAppEntered(true)}
            /> 
          : 
          null
      }
      <div 
        className="app" 
        style={{
          display: appEntered ? 'flex' : 'none',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
        >
        <Header />
        <Content />
      </div>
    </>
  );
}

export default App;
