import React from 'react';
import './Header.css'

const title = "LA PARTE DE ATRAS DE LA ARPILLERA"

function Header() {
  return (
    <h1 className="header" >
      {title}
    </h1>
  )
}

export default Header