function gaLoader() {
    let analyticsLoader = document.createElement("script")
    analyticsLoader.id = 'ga-load'
    analyticsLoader.async = true
    analyticsLoader.src = "https://www.googletagmanager.com/gtag/js?id=G-1DTGBHF2SJ"

    return analyticsLoader
}

function gaScript() {
    let analyticsScript = document.createElement("script")
    analyticsScript.id = 'ga-script'
    analyticsScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
    
        gtag('config', 'G-1DTGBHF2SJ');
    `
  
    return analyticsScript
  }
  
function gaScriptsPresent() {
    return document.getElementById('ga-load') && document.getElementById('ga-script')
}

function prependGAToHead() {
    document.head.prepend(gaLoader(), gaScript())
}

function injectAnalytics() {  
    if (!gaScriptsPresent()) { prependGAToHead() }
}

export default injectAnalytics