import React from 'react';
import './LandingPage.css'

interface LandingPageProps {
    exitAction: () => void;
}

function LandingPage({exitAction}: LandingPageProps) {
    return (
        <div
            className="landingpage-container"
            style={{

            }}
        >
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/1_web_sol.jpg' />
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/2_web_sol.jpg' />
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/3_web_sol.jpg' />
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/4_web_sol.jpg' />
            </div>
            <div className='landingpage-item' style={{position: 'relative'}}>
                <button 
                    style={{
                        color: 'white',
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        padding: '0em 2em', 
                        backgroundColor: 'transparent',
                        border: 'solid 2px white',
                        cursor: 'pointer',
                        fontFamily: 'AccidentalPresident'
                    }}
                    onClick={exitAction}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'grey',
                        opacity: '50%',
                        zIndex: -1
                    }}>

                    </div>
                    <h1>ENTRAR</h1>
                </button>
                <img className='landingpage-img' alt='Landing Page' src='/assets/5_web_sol.jpg' style={{objectPosition: 'bottom'}}/>
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/6_web_sol.jpg' />
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/7_web_sol.jpg' />
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/8_web_sol.jpg' />
            </div>
            <div className='landingpage-item' >
                <img className='landingpage-img' alt='Landing Page' src='/assets/9_web_sol.jpg' />
            </div>
        </div>
    )
}

export default LandingPage