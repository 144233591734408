import React, { Dispatch, useState } from 'react';
import './WriteUp.css'
import ccaLogo from './CCA_RGB_black_e.svg'

function WriteUp() {
	const maxPage = 3

	const [lang, setLang] = useState('ESP')
	const [activePage, setActivePage] = useState(1)

	return (
		<div className="write-up">
			<div
				style={{
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<div style={{marginBottom: '1em'}}>
					<SetLangButton setLang={setLang} lang={'ESP'} currentLang={lang} /> | <SetLangButton setLang={setLang} lang={'ENG'} currentLang={lang}/>
				</div>
				<div>
					{texts[activePage][lang].map((text) => 
						text.startsWith('/static') ?
						<img className='cca-logo' src={text} alt='logo' key={ccaLogo}/>
						// null
						:
						<p className='writeup-text' key={btoa(escape(text))}>{text}</p>
					)}
				</div>
				<div
					style={{
						marginTop: '1em',
						marginBottom: '1em',
						display: 'flex',
						alignItems: 'center'
					}}
				> 
					<div 
						style={{
							fontSize: '1.5em',
							marginRight: '1em', 
							fontWeight: 'bold',
							cursor: 'pointer',
							visibility: activePage <= 1 ? 'hidden' : 'visible'
						}}
						onClick={() => setActivePage(activePage - 1)}
					>
						←
					</div>
					<div>
						{`${activePage} / ${maxPage}`}
					</div>
					<div 
						style={{
							fontSize: '1.5em',
							marginLeft: '1em', 
							fontWeight: 'bold',
							cursor: 'pointer',
							visibility: activePage >= maxPage ? 'hidden' : 'visible'
						}}
						onClick={() => setActivePage(activePage + 1)}
					>
						→
					</div>
				</div>
				
			</div>
		</div>
	);
}

interface ButtonProps {
	currentLang: string;
	lang: string;
	setLang: Dispatch<string>;
}

function SetLangButton({currentLang, lang, setLang}: ButtonProps) {
	return (
		<button disabled={currentLang === lang} onClick={() => setLang(lang)}>{lang}</button>
	)
}

const texts: MessagesType = {
	1: {
		'ENG': [
			'The back of the Arpillera is as if we could not evade our memory. To me, that is the most profound meaning of the backs. The back is everything that cannot escape the present. If I omit the back, I am wanting to build a memory that is not troublesome, because it is just the front.',
			"The back is the conflicts, the complexities, the back is everything that cannot be hidden, but it can neither be said, because it's the back. It's the fingerprints, the stains, like wounds, like sutures also. They are constellations. And it’s the resistance to not satisfy the official memory. Because the arpilleras have been long treated as a minor art form, and I believe that's disrespectful to the work of all the women, behind the arpilleras.",
			"Therefore if we don't show the back, we don't show our memory as it is; painful, imperfect. It's to get out of this imposture of the idea of an official memory, that looks great in the museum, but not in life.",
			'- Daniela Pizarro Torres ‘dani negri’ “La Parte de Atrás de la Arpillera”'
		],
		'ESP': [
			'La parte de atrás de las arpilleras es como si no pudiéramos evadir nuestra memoria. Para mi ese es el significado profundo de los reversos. El reverso es todo lo que no se puede escapar del presente. Si omito el reverso yo estoy queriendo construir una memoria que no haga problemas, porque es solo el derecho.',
			'Pero el reverso son los conflictos, las complejidades. El reverso es todo eso que no se puede ocultar, pero que tampoco se puede decir, porque es reverso. Es huella, mancha, son como heridas, son como suturas también. Son constelaciones. Y es la resistencia a no darle placer a la memoria oficial. Porque las arpilleras llevan mucho tiempo siendo tratadas como arte menor, y creo que eso es una falta de respeto al trabajo de todas las mujeres que hay detrás de las arpilleras.',
			'Entonces, si no mostramos el revés, no mostramos nuestra memoria tal cual es dolorosa, no es pareja. Es salir de esta impostura de esta idea de memoria oficial, que queda muy bien en el museo pero no queda muy bien en la vida.',
			'- Daniela Pizarro Torres ‘dani negri’ “La Parte de Atrás de la Arpillera”'
		]
	},
	2: {
		'ENG': [
			'The process of making this piece resembled that of making an audiovisual Arpillera. Through the collection of personal histories - in the form of interviews (in-person and online), phone recordings and personal footage - filmed or sent to us by makers in Lo hermida, La Patagonia, Los Andes, Coquimbo, La Serena, Malmö, Chicago and Toronto, we wanted to make a piece that - like the Arpillera - contained the history of resistance of our ancestors and peers.',
			'The footage was initially gathered to be presented at the Textile Society of America’s Hidden Stories/Human Lives online October 15, 16, and 17, 2020. By presenting embodied experiences of primary sources, we were trying to resist a colonial and ethnographic approach to research, move towards a culture of consent and combat the anonymity of Arpilleristas, whose names have been replaced by those of collectors and collections around the world.'
		],
		'ESP': [
			'El proceso de realización de esta pieza fue semejante al de hacer una Arpillera audiovisual, para la cual se recopilaron entrevistas (en persona y online), grabaciones telefónicas y archivos personales de bordadorxs en Lo Hermida, La Patagonia, Los Andes, Coquimbo, La Serena, Malmö, Chicago y Toronto. La pieza creada - como la Arpillera - contiene la historia de la resistencia de nuestrxs antepasadxs y compañerxs.',
			'El material fue reunido en un comienzo para ser presentado en el Simposio de la Sociedad de Textiles de América, “Historias Escondidas/Vidas Humanas” realizado el 15, 16 y 17 de Octubre del 2020.'
		]
	},
	3: {
		'ENG': [
			'We acknowledge the support of the Canada Council for the Arts',
			'Nous remercions le Conseil des arts du Canada de son soutien',
			ccaLogo
		],
		'ESP': [
			'Agradecemos al Consejo de Artes de Canadá por su apoyo.',
			ccaLogo
		]
	}
}

type MessagesType = {[x: number]: MessageType}
type MessageType = {[x: string]: string[]}

export default WriteUp;