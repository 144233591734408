import React from 'react';

function VideoDisplay() {
    return (
        <>
        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
            <iframe 
            title={'hello'} 
            src={"https://player.vimeo.com/video/478681936?portrait=0"} 
            style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
            frameBorder="0" 
            allow="autoplay; fullscreen" 
            allowFullScreen
            >
            </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        </>
    )
}

export default VideoDisplay