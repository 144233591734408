import React from 'react';
import VideoDisplay from '../VideoWindow';
import WriteUp from '../WriteUp';
import './Content.css'

function Content() {
    return (
        <div className="content-container">
            <div className='content-video'>
                <VideoDisplay />
            </div>
            <div className='content-text'>
                <WriteUp />
            </div>
        </div>
    )
}

export default Content